import Vue from 'vue';
import { headroom } from 'vue-headroom';
import BootstrapVue from 'bootstrap-vue';
import AOS from 'aos';
import Flickity from 'flickity';



// Plugins
Vue.use(BootstrapVue);

// Flickity
window.Flickity = Flickity;

// Global SCSS
import './scss/main.scss';

// Initialize AOS
AOS.init({
  duration: 800,
});

const navbar = document.querySelector('#JS-navbar');
if (navbar) {
  new Vue({
    el: '#JS-navbar',
    components: {
      headroom,
    },
  })
}

